import React from 'react'

const CSuppense = function (props: any, TagC: any) {
  return (
    <React.Suspense fallback={<CSuppense.LazyLoading />}>
      <TagC {...props} />
    </React.Suspense>
  )
}

CSuppense.LazyLoading = () => {
  return <div></div>
}
export default CSuppense
