export const getTextWB = (hexColor: string, opts: any = {}) => {
  let _colorForDark = opts.colorForDark || '#ffffff'
  let _colorForLight = opts.colorForLight || '#000000'
  if (opts && opts.fix && opts.fix[hexColor]) {
    //tra ve theo danh sach
    return opts.fix[hexColor]
  }
  let _lumina = getLumina(hexColor)
  if (_lumina < 128) {
    return _colorForDark
  }
  return _colorForLight
}

export const getLumina = (hexColor: string) => {
  if (hexColor && hexColor.startsWith('#')) {
    var c = hexColor.substring(1) // strip #
    var rgb = parseInt(c, 16) // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff // extract red
    var g = (rgb >> 8) & 0xff // extract green
    var b = (rgb >> 0) & 0xff // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
    return luma
  }
  return 0
}

export const nameOfColorsBootstrap = [
  'primary',
  'danger',
  'success',
  'secondary',
  'warning'
]

export const validColorBootstrap = (color: string) => {
  if (color && nameOfColorsBootstrap.indexOf(color) > -1) {
    return true
  }
  return false
}

export const randomHextColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}

export const shadeColor = (color: string, decimal: number): string => {
  const base = color.startsWith('#') ? 1 : 0

  let r = parseInt(color.substring(base, 3), 16)
  let g = parseInt(color.substring(base + 2, 5), 16)
  let b = parseInt(color.substring(base + 4, 7), 16)

  r = Math.round(r + (r * decimal) / 100)
  g = Math.round(g + (g * decimal) / 100)
  b = Math.round(b + (b * decimal) / 100)

  r = r < 255 ? r : 255
  g = g < 255 ? g : 255
  b = b < 255 ? b : 255

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16)
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16)
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16)

  return `#${rr}${gg}${bb}`
}
