import { RGSquareBracket } from '../helpers/Regex'
import { isNullOrEmpty, isObject, isString } from '../helpers/Is'
import { json2Obj } from '../helpers/Json'
import MyPageLayout from '../components/MyPageLayout'
import MyPageHelpers from '../components/MyPage/Helpers'
import { IConfigPageServer } from '../interface'
import Constant from 'src/helpers/Constants'
/**
 * APIName: "AppScreenUIControlSetting"
Config: null
Id: "787ffea3-e072-4ed7-8cfb-23e6ffd5b766"
RequestData: null
ScreenCode: "RP_ANALYTIC_ON_UPLOAD"
Title: "Analytic On Upload File"
Type: "SIDE_MENU"
UIType: "Tab"
UIUrl: "/rp-analytic-uploadfile"
 */

interface IConfigPage {}
// const Key = {
//   APIName: 'APIName',
//   ScreenCode: 'ScreenCode',
//   UIUrl: 'UIUrl',
//   UIType: 'UIType',
//   Title: 'Title',
//   Id: 'Id',
//   Contents: 'Contents'
// }
class ConfigPage implements IConfigPage {
  _configPageServer?: IConfigPageServer
  _config: any
  constructor() {}
  parseConfigPageServer(obj: IConfigPageServer) {
    this._configPageServer = obj
    this._config = {}
    if (obj && obj.Config) {
      if (isString(obj.Config)) {
        this._config = json2Obj(obj.Config)
      } else if (isObject(obj.Config)) {
        this._config = obj.Config
      }
    }
  }
  getApiController() {
    return this._configPageServer?.APIName
  }
  getScreenCode() {
    return this._configPageServer?.ScreenCode
  }
  getTitle() {
    return this._configPageServer?.Title
  }
  getId() {
    return this._configPageServer?.Id
  }
  getType() {
    let _type = this._configPageServer?.UIType
    if (_type == 'Client') {
      if (this._config && this._config.type) {
        _type = this._config.type
      }
    }
    //default type when null
    if (isNullOrEmpty(_type)) {
      _type = Constant.DefaultUITypeWhenNull
    }

    if (_type && isString(_type)) {
      _type = _type.toLocaleLowerCase()
    }
    return _type || ''
  }
  getWrapMyPageLayout(props: any) {
    let _typeWrapLayout = this._config.wrapLayout
    let _configWrapLayout = {}
    if (isString(this._config.wrapLayout)) {
      _typeWrapLayout = this._config.wrapLayout
    } else if (isObject(this._config.wrapLayout)) {
      _typeWrapLayout = this._config.wrapLayout.type
      _configWrapLayout = this._config.wrapLayout.config
    }
    return MyPageLayout({
      ...props,
      type: _typeWrapLayout,
      config: _configWrapLayout
    })
  }
  getMyPageFn() {
    let _type = this.getType()
    // console.warn('getMyPageFn:', _type)
    if (MyPageHelpers.Types[_type]) {
      return MyPageHelpers.Types[_type]
    }
    return MyPageHelpers.Types._empty
  }
  getMyPage(props: any) {
    let _myPageFn = this.getMyPageFn()
    return _myPageFn(props)
  }

  //Static
  static createFromConfigPageServer(obj: IConfigPageServer) {
    const cp = new ConfigPage()
    cp.parseConfigPageServer(obj)
    return cp
  }
  static parseCategoryScreenList(list?: IConfigPageServer[]) {
    let _newData: any[] = []
    if (list && list.length > 0) {
      _newData = list.map((v) => {
        if (v.UIUrl && v.UIUrl.indexOf('[') > -1) {
          v.UIUrl = v.UIUrl.replace(RGSquareBracket, ':$1')
        }
        if (v.UIType === null) {
          v.UIType = 'Table'
        }
        return v
      })
    }
    return _newData
  }
}

export { IConfigPage }
export default ConfigPage
