import ConfigPage from '../configs/ConfigPage'
import OneTable from './OneTable'
import OneScreenConfig from './OneScreenConfig'
import ConfigPagination from '../configs/ConfigPagination'
import OneFilters from './OneFilters'
import OneButton from './OneButton'
import OneTableFilterControl from './OneTableFilterControl'
import OneTableCell from './OneTableCell'
import OneTableColumn from './OneTableColumn'
import { IConfigPageServer } from '../interface'
import { get } from 'src/helpers/Utils'
interface IOnePage {
  _configPage: ConfigPage
  _configPagination: ConfigPagination
  _dataOptions: any
  _dataList: any
  _dataExtraData: any
  _oneTable: OneTable
  _oneScreenConfig: OneScreenConfig
}

interface IOnePageOptions {
  configPage?: any
}

class OnePage implements IOnePage {
  _configPage: ConfigPage
  _configPagination: ConfigPagination
  _dataOptions: any
  _dataOptionsPageConfigContents: IConfigPageServer[] | null
  _dataList: any
  _dataExtraData: any
  _dataTotal: any
  _oneTable: OneTable
  _oneFilters: OneFilters
  _oneScreenConfig: OneScreenConfig
  _buttonList: OneButton[] = []
  _filterList: OneTableFilterControl[] = []
  _filterListByFieldName: any = {}
  _oneTableColumnByField: (OneTableColumn | null)[] = []
  _refKeyTable: any = '' //bo sung refKey khi reload table
  _currentFilterTableButton: any = '' //bo sung current filter table button
  onForceUpdateUI: any = null
  constructor(opts: IOnePageOptions) {
    // console.warn('constructor onePage', opts)
    this._configPage = new ConfigPage()
    this._oneTable = new OneTable({})
    this._oneScreenConfig = new OneScreenConfig({})
    this._configPagination = new ConfigPagination({})
    this._oneFilters = new OneFilters()
    if (opts && opts.configPage) {
      this._configPage.parseConfigPageServer(opts.configPage)
    }
  }
  parseConfigPage(configPage: any) {
    if (configPage) {
      this._configPage.parseConfigPageServer(configPage)
    }
  }
  getScreenGUID() {
    return this._configPage.getId()
  }
  getPageContents() {
    if (this._configPage._configPageServer?.Contents) {
      return this._configPage._configPageServer?.Contents
    }
    return this._dataOptionsPageConfigContents
  }
  getQueryOptions() {}
  getQueryList() {}
  getQueryShared() {
    let _screenGUID = this.getScreenGUID()
    let _df: any = {}
    if (_screenGUID) {
      _df.ScreenGUID = _screenGUID
    }
    return {
      ..._df,
      ...this.getQueryPagination(),
      ...this.getQuerySort(),
      ...this.getQueryFilterServer()
    }
  }
  getQueryPagination() {
    if (this._oneScreenConfig._table.hasPagingRemote === true) {
      return this._configPagination.getQuery()
    }
    return null
  }
  getQueryFilter() {
    let _query = {
      FilterQuery: this._oneFilters.getQueryFilterClients()
    }
    return _query
  }
  getQueryFilterServer() {
    return this._oneFilters.getQueryFilter()
  }
  getQuerySort() {
    let _query = this._oneFilters.getQuerySort()
    if (this._oneScreenConfig._table.hasPagingRemote === true) {
      if (_query['SortQuery']) {
        _query['Sorts'] = _query['SortQuery']
        _query['UI_Sorts'] = _query['SortQuery']
      }
    }
    return _query
  }
  requestOptions() {}
  requestList() {}
  parseOptions(options?: any) {
    if (options) {
      this._dataOptions = options
      if (options.ButtonList) {
        this._buttonList = OneButton.createListButtonList(
          options.ButtonList,
          this
        )
      }
      this._dataOptionsPageConfigContents =
        get(options, 'PageConfig.Contents') || null
      this._oneScreenConfig.parseFromOptions(this._dataOptions)
      this._configPagination.updateFromOneScreenConfig(this._oneScreenConfig)
    }
  }
  parseListAndExtraData(data?: any) {
    if (data) {
      this._dataList = data.Data
      this._dataTotal = data.DataTotal
      this._dataExtraData = data.ExtraData
      this._oneScreenConfig.parseFromListAndExtraData(
        this._dataList,
        this._dataExtraData
      )
      //parse tablecell
      if (this._dataExtraData && this._dataExtraData.Columns) {
        let _fields = Object.keys(this._dataExtraData.Columns)
        if (_fields && _fields.length > 0) {
          for (let f of _fields) {
            this._oneTableColumnByField[f] = OneTableCell.createFromExtraData(
              this._dataExtraData,
              f,
              this
            )
          }
        }
      }

      //when has filterConfig.data
      //bo sung _filterListByFieldName cho truong hop load lai list, bi thay doi control filter -> gan vao listByField -> load lai thi xai lai
      if (this._oneScreenConfig._filter && this._oneScreenConfig._filter.data) {
        this._filterList = []
        for (let i = 0; i < this._oneScreenConfig._filter.data.length; i++) {
          let _cfFilter = this._oneScreenConfig._filter.data[i]
          let _fieldName = _cfFilter.fieldName
          if (_fieldName) {
            if (this._filterListByFieldName[_fieldName]) {
              this._filterList.push(this._filterListByFieldName[_fieldName])
            } else {
              this._filterListByFieldName[_fieldName] =
                new OneTableFilterControl({
                  ..._cfFilter,
                  onePage: this
                })
              this._filterList.push(this._filterListByFieldName[_fieldName])
            }
          }
        }
      }
      this._configPagination.updateFromDataList(data)
    }
  }
  getDataList() {
    return this._dataList
  }
  getDataWithFilterClient() {
    return this._oneFilters.getDataWithCurrentFilter(this._dataList)
  }
  getDataOptions() {
    return this._dataOptions
  }
  getSourceList() {
    return this._dataOptions
  }
  getDataExtraData() {
    return this._dataExtraData
  }
  getApiController() {
    return this._configPage.getApiController()
  }
  getOneTableColumnByFieldname(fieldName: string) {
    return this._oneTableColumnByField[fieldName]
  }
  getPageTitle() {
    let _title = this._configPage.getTitle()
    if (this._oneScreenConfig._header.title) {
      _title = this._oneScreenConfig._header.title
    }
    return _title
  }
  getButtonsList() {
    return this._buttonList
  }
  getFilterList() {
    return this._filterList
  }
  clearFilter() {
    OneTableFilterControl.clearAllFilter(this._filterList) //bo sung filterList
    this._oneFilters.updateCurrentFiters({})
  }
  onSearch() {}
  onFilter() {}
  onChangeFilter(fromOneFilterControl: OneTableFilterControl) {
    let _allFilterOut =
      OneTableFilterControl.getAllFilterOutFromListFilterControls(
        this._filterList
      )
    this._oneFilters.updateCurrentFiters(_allFilterOut)
    console.warn('onChangeFilter', fromOneFilterControl, _allFilterOut)
  }
  forceUpdateUI() {
    if (this.onForceUpdateUI) {
      this.onForceUpdateUI()
    }
  }
  static debug() {
    console.warn(OnePage)
  }
}

export default OnePage
