import { isObject, isString } from 'src/helpers/Is'
import { getTextWB } from 'src/helpers/Color'
import { get } from 'src/helpers/Utils'
import HConstant from 'src/helpers/Constants'
import { getCF } from 'src/helpers/CF'
interface IPropsOfCellType {
  extra: any
  row: any
  more?: any
  defaultStyle?: any
  defaultValue?: any
  custom?: any
  disabled?: any
  key?: any
  fnList?: any
}
export const getMoreInType = (props: IPropsOfCellType) => {
  const extra = props.extra
  let _more = {}
  if (extra != null) {
    let _type = extra.Type
    try {
      if (_type != null && _type.more) {
        _more = JSON.parse(_type.more)
      }
    } catch (error) {
      console.warn('Parser json more in Type error:', error, _type)
    }
  }
  return _more
}
export const getExtraStyle = (props: IPropsOfCellType) => {
  const extra = props.extra
  const row = props.row
  const custom = props.custom
  const disabled = props.disabled
  let _style = props.defaultStyle || {}
  if (extra != null) {
    let _styleEx = extra.Style
    if (_styleEx != null) {
      if (isString(_styleEx) == true) {
        try {
          _style = Object.assign(_style, JSON.parse(_styleEx))
        } catch (error) {
          // console.log('getExtraStyle error:', _style, error)
        }
      } else if (isObject(_styleEx) == true) {
        _style = Object.assign(_style, _styleEx)
      }
    }
    if (extra.Type != null && row != null) {
      if (extra.Type.bgF != null) {
        if (row[extra.Type.bgF] != null) {
          let _color = getTextWB(row[extra.Type.bgF])
          _style = Object.assign(_style, {
            background: row[extra.Type.bgF],
            color: _color
          })
        } else if (
          extra.Type.bgF.startsWith('#') ||
          extra.Type.bgF.startsWith('linear-gradient')
        ) {
          let _color = getTextWB(extra.Type.bgF)
          _style = Object.assign(_style, {
            background: extra.Type.bgF,
            color: _color
          })
        }
      }
      if (extra.Type.colorF != null) {
        if (row[extra.Type.colorF] != null) {
          _style = Object.assign(_style, { color: row[extra.Type.colorF] })
        } else if (extra.Type.colorF.startsWith('#')) {
          _style = Object.assign(_style, { color: extra.Type.colorF })
        }
      }
    }
    let _more = getMoreInType({ extra, row }) as any
    // console.log('_more:',_more);
    if (_more) {
      try {
        if (_more.style) {
          let _styleInMore = _more.style || {}
          if (_styleInMore) {
            _style = Object.assign(_style, _styleInMore)
          }
        }
        if (_more.colorF != null) {
          if (row[_more.colorF] != null) {
            _style = Object.assign(_style, { color: row[_more.colorF] })
          }
        }
      } catch (error) {}
    }
  }
  // console.log('getExtraStyle:',extra,_style);
  if (row && row.StyleRow && typeof row.StyleRow == 'object') {
    _style = Object.assign(_style, row.StyleRow)
  }
  if (row && row.UI_StyleRow) {
    if (typeof row.UI_StyleRow == 'object') {
      _style = Object.assign(_style, row.UI_StyleRow)
    } else if (typeof row.UI_StyleRow == 'string') {
      try {
        let _styleRow = JSON.parse(row.UI_StyleRow)
        if (_styleRow) {
          _style = Object.assign(_style, _styleRow)
        }
      } catch (error) {
        console.warn('Error parse UI_StyleRow', error)
      }
    }
  }
  if (custom) {
    _style = Object.assign(custom, _style)
  }

  //check disable
  if (disabled != null) {
    if (
      _style.backgroundColor == null &&
      _style.background == null &&
      _style.backgroundImage == null
    ) {
      if (disabled == true) {
        _style.background = 'transparent'
      } else {
        _style.background = 'white'
      }
    }
  }
  return _style
}
export const getExtraClassName = (props: IPropsOfCellType) => {
  const extra = props.extra
  const row = props.row
  const more = props.more || getMoreInType(props)
  let _className = ''
  if (
    getConfigTrueByAllLevel({
      key: HConstant.AppConfig.showClassFieldOnCell,
      extra,
      row,
      more
    })
  ) {
    let _fieldName = get(extra, 'fieldName')
    _className += ` col-${_fieldName}`
  }
  if (extra != null) {
    if (extra.ClassName) {
      _className += ` ${extra.ClassName}`
    }
  }
  if (more) {
    if (more.className) {
      _className += ` ${more.className}`
    }
    if (extra.fieldName && more.classNameWithColField != null) {
      if (more.classNameWithColField === true) {
        _className += ` col-${extra.fieldName}`
      } else {
        _className += ` ${more.classNameWithColField}-${extra.fieldName}`
      }
    }
  }
  return _className
}

export const getConfigTrueByAllLevel = (props: IPropsOfCellType) => {
  const extra = props.extra
  const row = props.row
  const more = props.more || getMoreInType(props)
  const key = props.key
  const _configInTableConfig = getTableConfig({
    extra,
    row,
    more,
    key
  })
  if (more && more[key] === true) {
    // console.warn("more:",more)
    return true
  } else if (_configInTableConfig === true) {
    // console.warn("_configInTableConfig:",_configInTableConfig)
    return true
  } else if (getCF(key) === true) {
    // console.warn("AppConfig:",HConfig)
    return true
  }
  return false
}
export const getTableConfig = (props: IPropsOfCellType) => {
  const fnList = props.fnList
  let _value = props.defaultValue
  let _fnList = fnList || getFnList(props)
  if (_fnList && _fnList.fnGetTableConfig) {
    _value = _fnList.fnGetTableConfig(props.key, props.defaultValue)
  }
  return _value
}

export const getFnList = (props: IPropsOfCellType) => {
  const extra = props.extra
  const row = props.row
  const more = props.more || getMoreInType(props)
  let _fnList = {}
  if (extra != null) {
    _fnList = extra.fnList || {}
    let _more = more
    return {
      ..._fnList,
      ...{
        fncGetRow: () => {
          return row
        },
        fncGetMore: () => {
          return _more
        },
        fncGetConfigByKeyInMore: (key: string) => {
          return More.getConfigByKeyInMore({ extra, row, more: _more, key })
        },
        fncGetQuery: () => {
          return More.getQuery({ extra, row, more: _more })
        },
        fncNeedReload: (response: any) => {
          return More.needReload({ extra, row, more: _more, response })
        }
      }
    }
  }
  return _fnList
}
export const More = {
  getTextDisplay: (props: IPropsOfCellType) => {
    return props
  },
  getConfigByKeyInMore: (props: IPropsOfCellType) => {
    return props
  },
  getQuery: (props: IPropsOfCellType) => {
    return props
  },
  needReload: (opts: any) => {
    return opts
  }
}
