import * as React from 'react'
// khong dung ReactDOMServer truc tiep, react-native se bi loi
// import ReactDOMServer from 'react-dom/server'
import MyIcon from 'src/components/MyIcon'
const HComponent = {
  getHtmlFromConfigComponent: (item: any, ReactDOMServer: any) => {
    if (item) {
      if (item.myIcon) {
        let _moreProps: any = {}
        if (item.className) {
          _moreProps.className = item.className
        }
        if (item.style) {
          _moreProps.style = item.style
        }
        let _component = <MyIcon configIcon={item.myIcon} {..._moreProps} />
        if (ReactDOMServer && ReactDOMServer.renderToStaticMarkup) {
          return ReactDOMServer.renderToStaticMarkup(_component)
        } else {
          console.error('No ReactDOMServer')
        }
      }
    }
    return ''
  }
}

export default HComponent
