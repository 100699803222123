import OneScreenConfig from '../model/OneScreenConfig'

interface IConfigPagination {
  getQuery: () => object | null
}

interface IConfigPaginationOptions {
  pageStart?: number
  pageSize?: number
  fPage?: string
  fPageSize?: string
  afterChange?: (currentPage: number, pageSize: number) => void
}

interface IConfigPaginationFromCellMore {
  pageStart?: number
  pageSize?: number
}

class ConfigPagination implements IConfigPagination {
  _pageStart: number = 1
  _pageCurrent: number = 1
  _totalItems: number = 0
  _pageSize: number = 10
  _fPage: string
  _fPageSize: string
  _afterChange: (currentPage: number, pageSize: number) => void
  constructor(config: IConfigPaginationOptions) {
    this._pageStart = config.pageStart != null ? config.pageStart : 1
    this._pageCurrent = this._pageStart
    this._pageSize = config.pageSize != null ? config.pageSize : 10
    this._fPage = config.fPage || 'Page'
    this._fPageSize = config.fPageSize || 'PageSize'
    this._afterChange =
      config.afterChange != null ? config.afterChange : () => {}
  }
  next() {
    this._pageCurrent++
    this.afterChange()
  }
  previous() {
    this._pageCurrent--
    this.afterChange()
  }
  go(page: number) {
    this._pageCurrent = page
    this.afterChange()
  }
  goFist() {
    this._pageCurrent = this._pageStart
    this.afterChange()
  }
  //bo sung resetPage: sau khi filter server moi can reset page
  resetPage() {
    this._pageCurrent = this._pageStart
  }
  update(page?: number, pageSize?: number, totalItems?: number) {
    if (page != null) {
      this._pageCurrent = page
    }
    if (pageSize != null) {
      this._pageSize = pageSize
    }
    if (totalItems != null) {
      this._totalItems = totalItems
    }
  }
  validSetPageNumber() {}
  afterChange() {
    this._afterChange(this._pageCurrent, this._pageSize)
  }
  getQuery() {
    return {
      [this._fPage]: this._pageCurrent,
      [this._fPageSize]: this._pageSize
    }
  }

  updateFromOneScreenConfig(oneScreenConfig: OneScreenConfig) {
    if (oneScreenConfig) {
      this._pageSize =
        oneScreenConfig._table.optionsTable?.sizePerPage || this._pageSize
    }
  }
  updateFromDataList(data?: any) {
    if (data != null) {
      if (data.Total) {
        this._totalItems = data.Total
      } else if (data.Data && data.Data.length >= 0) {
        this._totalItems = data.Data.length
      }
    }
  }

  static createFromCellMore(configPagination: IConfigPaginationFromCellMore) {
    return new ConfigPagination({
      pageStart: configPagination.pageStart,
      pageSize: configPagination.pageSize
    })
  }
}

export default ConfigPagination
