import { mergeObjIfExist } from './Utils'
import { obj2Json, json2Obj } from './Json'
const LocalStorageConfig = {
  kPrefix: ''
}
const getKey = (key: string) => {
  return LocalStorageConfig.kPrefix + key
}
export const initLocalStorage = (config: any) => {
  mergeObjIfExist(LocalStorageConfig, config)
}
export const getObj = (key: string, df: object) => {
  let _v = getString(getKey(key), '')
  if (_v) {
    return json2Obj(_v, df)
  }
  return df
}
export const saveObj = (key: string, value: object) => {
  let _v = obj2Json(value)
  if (_v) {
    saveString(getKey(key), _v)
  }
}
export const saveString = (key: string, value: string) => {
  localStorage.setItem(getKey(key), value)
}
export const getString = (key: string, df: string) => {
  return localStorage.getItem(getKey(key)) || df
}
export const removeItem = (key: string) => {
  return localStorage.removeItem(key)
}
export const ConfigScreen = {
  key: {
    prefix: 'config_',
    filter: 'filter'
  },
  getConfig(key: string) {
    if (key != null) {
      return getObj(ConfigScreen.key.prefix + key, {})
    }
  },
  setConfig(key: string, obj: any) {
    if (key != null) {
      return saveObj(ConfigScreen.key.prefix + key, obj)
    }
  },
  clearAllConfig() {
    if (localStorage != null && Object.keys(localStorage) != null) {
      let _arrKeys = Object.keys(localStorage)
      for (let e of _arrKeys) {
        if (e.startsWith(ConfigScreen.key.prefix) == true) {
          delete localStorage[e]
        }
      }
    }
  },
  getSavedFilter(key: string) {
    if (key != null) {
      let _config = ConfigScreen.getConfig(key)
      if (_config != null && _config[ConfigScreen.key.filter] != null) {
        // console.log('getSavedFilter:', _config[ConfigScreen.key.filter])
        return _config[ConfigScreen.key.filter]
      }
    }
  },
  clearSavedFilter(key: string) {
    if (key != null) {
      let _config = ConfigScreen.getConfig(key)
      delete _config[ConfigScreen.key.filter]
      ConfigScreen.setConfig(key, _config)
    }
  },
  setSavedFilter(key: string, data: any) {
    if (key != null) {
      let _config = ConfigScreen.getConfig(key)
      _config[ConfigScreen.key.filter] = data
      ConfigScreen.setConfig(key, _config)
    }
  },
  clearCustomField({ screenCode, key }: { screenCode: string; key: string }) {
    if (key != null) {
      let _config = ConfigScreen.getConfig(screenCode)
      delete _config[key]
      ConfigScreen.setConfig(key, _config)
    }
  },
  setCustomField({
    screenCode,
    key,
    data
  }: {
    screenCode: string
    key: string
    data: any
  }) {
    if (key != null) {
      let _config = ConfigScreen.getConfig(screenCode) || {}
      if (_config) {
        _config[key] = data
      }
      ConfigScreen.setConfig(screenCode, _config)
    }
  },
  getCustomField({ screenCode, key }: { screenCode: string; key: string }) {
    if (screenCode != null) {
      let _config = ConfigScreen.getConfig(screenCode)
      if (_config != null && _config[key] != null) {
        return _config[key]
      }
    }
  }
}
