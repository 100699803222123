/* tslint:disable */
/* eslint-disable */
var origLog = console.log
var origWarn = console.warn
var origInfo = console.info

interface ILogInfo {
  countTest: number
  logTypeOne?: boolean
  disabled?: boolean
  listNoPage: string[]
  listNoMyUI: string[]
  listNoAnyType: string[]
}

const LogInfo: ILogInfo = {
  logTypeOne: true,
  disabled: false,
  countTest: 0,
  listNoPage: [],
  listNoMyUI: [],
  listNoAnyType: []
}

export const initLog = (opts: any) => {
  if (opts) {
    if (opts.logTypeOne != null) {
      LogInfo.logTypeOne = opts.logTypeOne
    }
  }
}

export const disableLog = () => {
  LogInfo.disabled = true
}
// console.log = function () {
//   var msgs: any[] = [],
//     prefix: string = window === window.top ? '[root]' : '[' + window.name + ']'
//   while (arguments.length) {
//     msgs.push(prefix + ': ' + [].shift.call(arguments))
//   }
//   origLog.apply(console, msgs)
// }
export const logW = (...data: any[]) => {
  origWarn.apply(console, data)
}
export const logParseJson = (...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  var msgs: any[] = ['%c[Parse Json]', 'background: #222; color: #bada55']
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origWarn.apply(console, msgs)
}

export const logApi = (...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  var msgs: any[] = ['%c[Api]', 'background: #009933; color: #fff']
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origLog.apply(console, msgs)
}

export const logTest = (...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  LogInfo.countTest++
  var msgs: any[] = [
    `%c[Test ${LogInfo.countTest}]`,
    'background: #ffffff; color: #ff00ff'
  ]
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origInfo.apply(console, msgs)
}

export const logExistType = (type: string, ...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  var msgs: any[] = [
    `%c[Exist Type: ${type}]`,
    'background: #ff6060; color: #fff'
  ]
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origWarn.apply(console, msgs)
}

export const logNoType = (opts: any = {}, type: string, ...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  if (LogInfo.logTypeOne === true) {
    let _list = opts.list
    if (_list.indexOf(type) > -1) {
      return
    } else {
      _list.push(type)
    }
  }
  var msgs: any[] = [
    `%c[${opts.prefix || 'No Type'}: ${type}]`,
    opts.style || 'background: #ff0000; color: #fff'
  ]
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origWarn.apply(console, msgs)
}
export const logNoMyUIType = (type: string, ...data: any[]) => {
  return logNoType(
    {
      list: LogInfo.listNoMyUI,
      prefix: 'No MyUI Type',
      style: 'background: #dc552d; color: #fff'
    },
    type,
    ...data
  )
}
export const logNoAnyType = (type: string, ...data: any[]) => {
  return logNoType(
    {
      list: LogInfo.listNoAnyType,
      prefix: 'No Type',
      style: 'background: #dc552d; color: #fff'
    },
    type,
    ...data
  )
}
export const logNoMyPageType = (type: string, ...data: any[]) => {
  return logNoType(
    {
      list: LogInfo.listNoPage,
      prefix: 'No MyPage Type',
      style: 'background: #dc552d; color: #fff'
    },
    type,
    ...data
  )
}

let logOneObj = {}
export const logOne = (msg: string, ...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  if (!logOneObj[msg]) {
    var msgs: any[] = [`%c[Log One:]`, 'background: #ff0000; color: #fff', msg]
    while (data.length) {
      msgs.push([].shift.call(data))
    }
    origWarn.apply(console, msgs)
    logOneObj[msg] = true
  }
}

export const logNeedOveride = (...data: any[]) => {
  if (LogInfo.disabled) {
    return
  }
  var msgs: any[] = [`%c[NeedOveride]`, 'background: #000000; color: #ff00ff']
  while (data.length) {
    msgs.push([].shift.call(data))
  }
  origWarn.apply(console, msgs)
}

export const logWarn = function () {
  if (LogInfo.disabled) {
    return
  }
  console.warn.apply(null, arguments)
}

export const logObjForCopy = function () {
  for (var i = 0; i < arguments.length; i++) {
    var _s = JSON.stringify(arguments[i], null, 2)
    _s = _s.replace(/"([^"]+)":/gm, '$1:')
    console.log(_s)
  }
}

export const logObjectPrettyPrint = function () {
  for (var i = 0; i < arguments.length; i++) {
    console.log(JSON.stringify(arguments[i], null, 2))
  }
}
